html {
    scroll-behavior: smooth;
}

.header-image {
    background-image: url('../images/hero_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 16/9;
}

.bg-header {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 64.49%, #000910 93.44%);
}


.button-bg {
    background: linear-gradient(97.24deg, #FF0009 -5.21%, #0036F3 102.43%);
}

.bg-buy-color {
    background: rgba(16, 255, 241, 0.05);
}

.roadmap-bg {
    background: rgba(16, 255, 241, 0.05);
    box-shadow: inset 10px 10px 17px rgba(0, 0, 0, 0.25);
}

.text-shadow {
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.5);
}


.border_image {
    border: 35px solid transparent;
    border-image: url('../images/join_button.png') 35;
}